"use strict";

var _preact = require("preact");

var _reactLoadable = _interopRequireDefault(require("react-loadable"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

if (process.env.NODE_ENV === 'development') {
  require('preact/devtools');
} else if (process.env.ADD_SW && 'serviceWorker' in navigator && location.protocol === 'https:') {}

const interopDefault = m => m && m.default ? m.default : m;

let app = interopDefault(require('preact-cli-entrypoint'));

if (typeof app === 'function') {
  let root = document.body.firstElementChild;

  let init = () => {
    let app = interopDefault(require('preact-cli-entrypoint'));

    _reactLoadable.default.preloadReady().then(() => {
      root = (0, _preact.render)((0, _preact.h)(app), document.body, root);
    });
  };

  if (module.hot) module.hot.accept('preact-cli-entrypoint', init);
  init();
}