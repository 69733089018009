import { TELECALLER_IPS } from '../../constants';

/**
 * Returns whether an userAgent is a bot
 * Bot useragents referenced from https://github.com/monperrus/crawler-user-agents/blob/master/crawler-user-agents.json
 * @param {*} userAgent
 */
export const isBot = (userAgent) => {
	const botsRegExp = /Googlebot\/|Googlebot-Mobile|Googlebot-Image|Googlebot-News|Googlebot-Video|AdsBot-Google([^-]|$)|AdsBot-Google-Mobile|Feedfetcher-Google|Mediapartners-Google|Mediapartners \\(Googlebot\\)|APIs-Google|bingbot|Slurp|Baiduspider/;
	return userAgent.match(botsRegExp) !== null;
}
/**
 * Returns whether an userAgent is a mobile
 * Regex referenced from https://github.com/juliangruber/is-mobile/blob/master/index.js
 * @param {*} userAgent
 */
export const checkMobile = (userAgent) => {
	const MobileTabRegex = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i;
	return MobileTabRegex.test(userAgent);
}
/**
 * Returns whether an userAgent is a webview
 * @param {*} userAgent
 */
export const checkWebView = (headers) => {
	if (headers['iswebview'] === 'android' || headers['iswebview'] === 'ios') {
		return true;
	} else {
		return false;
	}
}
/**
 * Returns whether webp format is supported
 * @param {*} headers
 */
export const checkWebPSupported = (headers) => {
	if (headers && headers.accept) {
		return (headers.accept.indexOf('image/webp') > -1);
	} else {
		return false;
	}
}
/**
 * Return whether the user is a internal_IP teleCaller user or not
 */
export const checkTeleCaller = (remoteIp) => {
	if (TELECALLER_IPS.includes(remoteIp)) {
		return true;
	} else {
		return false;
	}
}