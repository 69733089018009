import { h, Component } from 'preact';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Helmet from 'preact-helmet';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { combineReducers } from 'redux';

import coreShellConfig from 'q-core/src/shellConfig';
import {exampleConfig as goodsMsiteShellConfig, desktopShellConfig as goodsDesktopShellConfig} from 'q-goods/src/shellConfig';
import { shellConfig as carsMsiteShellConfig, desktopShellConfig as carsDesktopShellConfig } from 'q-cars/src/shellConfig';

import Shell from 'q-components/components/shell';
import coreReducers from 'q-core/src/redux/reducers';
import goodsReducers from 'q-goods/src/redux/reducers';
import carsReducers from 'q-cars/src/redux/reducers';
import commonReducer from './src/redux/reducers/common';
import addDefaulHeaders from 'q-core/src/redux/middleware/addDefaultHeaders';
import axiosMiddleware from 'q-core/src/redux/middleware/axiosMiddleware';
import carsAxiosMiddleware from 'q-cars/src/redux/middleware/axiosMiddleware';

import { checkMobile } from './src/server/helpers/utils/userAgent';

import styles from './index.scss';

export const mergedConfig = [
	...goodsMsiteShellConfig.map(config=> ({ vertical: 'goods', middlewares: [axiosMiddleware], ...config })),
	...coreShellConfig.map(config => ({ vertical: 'core', middlewares: [axiosMiddleware], ...config })),
	...carsMsiteShellConfig.map(config => ({ vertical: 'cars', middlewares: [carsAxiosMiddleware], ...config })),
];

export const desktopMergedConfig = [
	...goodsDesktopShellConfig.map(config=> ({ vertical: 'goods', middlewares: [axiosMiddleware], ...config })),
	...coreShellConfig.map(config => ({ vertical: 'core', middlewares: [axiosMiddleware], ...config })),
	...carsDesktopShellConfig.map(config => ({ vertical: 'cars', middlewares: [carsAxiosMiddleware], ...config })),
];

export const reducers = combineReducers({
	cars: carsReducers,
	core: coreReducers,
	common: commonReducer,
	goods: goodsReducers
});

const defaultMiddlewares = [addDefaulHeaders];



const LoadableCoreApp = Loadable({
	loader: () => import('q-core/src'),
	loading: () => <div className='q-loader' />,
	delay: 0,
	render (loaded, props) {
		let CoreApp = loaded.default;
		return <CoreApp {...props} />;
	}
});

const LoadableGoodsApp = Loadable({
	loader: () => import('q-goods/src'),
	loading: () => <div className='q-loader' />,
	delay: 0,
	render (loaded, props) {
		let CoreApp = loaded.default;
		return <CoreApp {...props} />;
	}
});


const LoadableCarsApp = Loadable({
	loader: () => import('q-cars/src'),
	loading: () => <div className='q-loader' />,
	delay: 0,
	render (loaded, props) {
		let CarsApp = loaded.default;
		return <CarsApp {...props} />;
	}
});

class QuikrApp extends Component {
	constructor (props) {
		super(props);
		this.state = { isOnline: true };
	}

	handleOnline = () => {
		this.setState({
			isOnline: true
		});
	}

	handleOffline = () => {
		this.setState({
			isOnline: false
		});
	}

	componentDidMount () {
		this.setState({
			isOnline: navigator.onLine
		});
		window.addEventListener('offline', this.handleOffline);
		window.addEventListener('online', this.handleOnline);
	}

	componentWillUnmount () {
		window.removeEventListener('online', this.handleOnline);
		window.removeEventListener('offline', this.handleOffline);
	}

	render (props, state) {
		const url = props.url;
		const { isOnline } = state;
		const { onModuleReport = () => {},overrideCityCookie,cityFromServer, store, isBot, isWebview } = props;
		let { isMobile } = props;
		if (__CLIENT__ && typeof isMobile === 'undefined' && typeof window !== 'undefined' && typeof navigator !== 'undefined') {
			isMobile = checkMobile(navigator.userAgent);
		}
		return (
			<div id='quikr-actual-root'>
				<Loadable.Capture report={onModuleReport}>
					<div className={`app ${isOnline ? 'online' : 'offline'}`}>
						<Shell config={isMobile ? mergedConfig : desktopMergedConfig} isDesktop={!isMobile} location={url} onModuleReport={onModuleReport} overrideCityCookie={overrideCityCookie} cityFromServer={cityFromServer} isBot={isBot} isWebview={isWebview}>
							<div className='app-router'>
								<TransitionGroup>
									<CSSTransition key={url} classNames='fade' timeout={300}>
										<Switch>
											<Route path="/(cars|cars-bikes|bikes-scooters)">
												<div>
													<LoadableCarsApp store={store} />
												</div>
											</Route>

											<Route path="/(home-lifestyle|electronics-appliances|mobiles-tablets|escrow|bazaar|quikr-assured-products.*)">
												<LoadableGoodsApp store={store} isMobile={isMobile}/>
											</Route>

											<Route path="/(cars|cars-bikes|bikes-scooters)">
			                                    <div>
			                                        <LoadableCarsApp store={store} />
			                                    </div>
			                                </Route>

											<Route path='/(pets|entertainment|events|matrimonial)' >
												<div>
													<LoadableCoreApp store={store} />
												</div>
											</Route>
											<Route path='/post-classifieds-ads' >
												<div>
													<LoadableCoreApp store={store} />
												</div>
											</Route>
											<Route path='/PostAd/' >
												<div>
													<LoadableCoreApp store={store} />
												</div>
											</Route>
											<Route path='/:cityName?'>
												<div>
													<LoadableCoreApp store={store} />
												</div>
											</Route>
										</Switch>
									</CSSTransition>
								</TransitionGroup>
							</div>
						</Shell>
					</div>
				</Loadable.Capture>
			</div>
		);
	}
}

export { Loadable, Shell, defaultMiddlewares, Helmet };

export default QuikrApp;